import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fab,
  faTwitter,
  faFacebook,
  faLinkedin,
  faGithub,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
  faClock,
  faComment,
  faEye,
  faHourglassStart,
  faInfoCircle,
  faPlay,
  faSearch,
  faStar,
  faThumbsUp,
  faUser,
  faFilter,
  faCalendar,
  faAddressCard,
  faLock,
  faEnvelopeOpenText,
  faEnvelope,
  faInbox,
  faExternalLinkAlt,
  faChevronCircleDown,
  faChevronDown,
  faPlayCircle,
  faTimes,
  faTicketAlt,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';
import {
  faPhone,
  faCity,
  faGlobe,
  faMapMarked,
  faBirthdayCake,
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faSearch,
  faUser,
  faThumbsUp,
  faPlay,
  faEye,
  faComment,
  faStar,
  faClock,
  faInfoCircle,
  faHourglassStart,
  faEye,
  faClock,
  faFilter,
  faCalendar,
  faAddressCard,
  faLock,
  faEnvelope,
  faEnvelopeOpenText,
  faUser,
  faInbox,
  faExternalLinkAlt,
  faFacebook,
  faInstagram,
  faTwitter,
  faTimes,
  faSearch,
  faUser,
  faThumbsUp,
  faPlay,
  faEye,
  faComment,
  faStar,
  faClock,
  faInfoCircle,
  faHourglassStart,
  faEye,
  faClock,
  faFilter,
  faCalendar,
  faAddressCard,
  faLock,
  faEnvelope,
  faEnvelopeOpenText,
  faUser,
  faInbox,
  faExternalLinkAlt,
  faPhone,
  faCity,
  faGlobe,
  faMapMarked,
  faBirthdayCake,
  faChevronCircleDown,
  faChevronDown,
  faPlayCircle,
  faTicketAlt,
  faDollarSign,
  faYoutube
);
