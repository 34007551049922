import './App.css';
import * as classes from './components/NavBars/NavBar.module.css';

import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useMyContext } from './contexts/StateHolder';
import queryString from 'query-string';

import settings from './config_settings.json';
import BottomBar from './components/Footers/BottomBar';

import { getCategories, purchasePackage } from './scripts/dataHandlers';

import './Shared/FontAwesomeIcon.js';

import ContactUs from './components/ContactUs/ContactUs';

import { useCookies } from 'react-cookie';

import LoginBTN from './components/Login/LoginBTN';

import NavBarButton from './components/NavBars/NavBarButton';

import NavBar from './components/NavBars/NavBar';

import Banner from './components/ViewAssets/Sliders/Banner';

import PurchaseFlow from './components/Packages/PurchaseFlow';
import { useTranslation } from 'react-i18next';
import TOS from './components/TOS/TOS';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import DetailsVideo from './components/Details/DetailsVideo';
import LoginForm from './components/Login/LoginForm';
import Profile from './components/Profile/Profile';
import SignUpForm from './components/SignUp/SignUpForm';
import DetailsSeasonsAndEpisodes from './components/Details/DetailsSeasonsAndEpisodes';

// react date time range css
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// toastify
import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';
import UpcomingEvent from './components/Events/Event/UpcomingEvent';

function App() {
  // Bring stateholders from context
  const {
    setAllCategories,

    setKey,

    setOrganizationId,

    setLanguage,
    isResponsiveclose,
    user,
    setUser,
  } = useMyContext();
  // organizationSecret
  const [cookies, setCookie] = useCookies(['osec']);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  /**** STEP 1, INIT PROGRAM ****/
  useEffect(() => {
    async function initProgram() {
      try {
        // TODO: Import from suite, now importing just from settings.json

        // Get organization data (id and key)

        const getOrganization = settings.organization;

        // Set organizationId to context
        setOrganizationId(getOrganization.organizationId);

        // Set key to context
        setKey(getOrganization.key);

        console.log(cookies, 'cookies');
        if (cookies?.userData?.userToken) {
          // alert('user');
          // Get user from context and copy it to modifiedUser
          let modifiedUser = { ...user };

          // Change status of user as logged in
          modifiedUser.loggedIn = true;

          // Set userToken from cookies
          modifiedUser.userId = cookies?.userData?.userId;

          // Set userToken from cookies
          modifiedUser.userToken = cookies?.userData?.userToken;

          // rn admin is harcoded need to get it from api

          modifiedUser.admin = true;
          // modifiedUser.organizationId = 124292109;
          modifiedUser.organizationId = 150723563;

          // Update user in context, with modified data
          setUser(modifiedUser);
        }

        // Get language from settings
        const getLanguage = settings.language;

        // Set language to context
        setLanguage(getLanguage);
        // Get categories from datahandler

        // let params = queryString.parse(
        //   'organizationId=3409909&paymentMethod=3&method=paytrail&productId=5033501&PAYMENT_ID=114513765058&TIMESTAMP=1655294716&STATUS=PAID&PAYMENT_METHOD=1&AMOUNT=10.00&ORDER_NUMBER=5035201&RETURN_AUTHCODE=D6F5DDD37BC93A37524EEFBFFB7CABDDB528FCB77B49472DD24014F6209C6503'
        // );
        // const purchaseResponse = await purchasePackage(
        //   cookies?.userData?.userToken,
        //   params
        // );
        // console.log('purchaseResponseAPP', purchaseResponse);

        // purchaseResponse();
      } catch (err) {
        console.log(err);
      }
    }

    initProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (cookies?.userData?.userToken) {
  //     setLoading(true);
  //     // alert('user');
  //     // Get user from context and copy it to modifiedUser
  //     let modifiedUser = { ...user };

  //     // Change status of user as logged in
  //     modifiedUser.loggedIn = true;

  //     // Set userToken from cookies
  //     modifiedUser.userId = cookies?.userData?.userId;

  //     // Set userToken from cookies
  //     modifiedUser.userToken = cookies?.userData?.userToken;

  //     // rn admin is harcoded need to get it from api

  //     modifiedUser.admin = true;
  //     // modifiedUser.organizationId = 124292109;
  //     modifiedUser.organizationId = 150723563;

  //     // Update user in context, with modified data
  //     setUser(modifiedUser);
  //     setLoading(false);
  //   }
  // }, [cookies?.userData?.userToken]);

  return !loading ? (
    <>
      {' '}
      <div className='App'>
        <div className='container'>
          {/* <NavBar
            links={
              <>
                <div className={classes.navBarRow_primary}></div>
              </>
            }
          /> */}
          <Switch>
            <Route path='/' exact>
              {/* <Redirect to='/event/177994676/178341745' /> */}

              <Redirect to='/event/177994676/178341745' />
            </Route>

            <Route path={`/${settings.routes.upcomingEvent}`} exact>
              <div style={{ flex: 1 }}>
                <UpcomingEvent routes={settings.routes} />
              </div>
            </Route>
            <Route
              path={`/${settings.routes.packages}/:itemType?/:itemId?/:receipt?`}
              exact
            >
              <PurchaseFlow settings={settings.components.purchaseflow} />
            </Route>

            <Route path={`/${settings.routes.tos}`}>
              <TOS />
            </Route>
            <Route path={`/${settings.routes.privacyPolicy}`}>
              <PrivacyPolicy />
            </Route>

            <Route path={`/${settings.routes.videoRoute}/:orgId/:asset`} exact>
              <DetailsVideo
                styles={settings.styles}
                routes={settings.routes}
                hideBanner={true}
                hideInfoData={true}
                showPublishedDate={true}
              />
            </Route>
            {/* ROUTE FOR SERIE PLAY VIEW*/}
            <Route
              path={`/${settings.routes.svodSerieRoute}/:orgId/:asset`}
              exact
            >
              <div style={{ marginBottom: '5%' }}>
                <DetailsVideo
                  routes={settings.routes}
                  hideBanner={true}
                  hideInfoData={true}
                />
                <DetailsSeasonsAndEpisodes
                  playVideoRoute={settings.routes.svodSerieRoute}
                />
              </div>
            </Route>
          </Switch>
          <Route path={`/${settings.routes.contactus}`}>
            <ContactUs />
          </Route>

          <BottomBar routes={settings.routes} />
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}

export default App;
