import { useState } from 'react';

export const useQuantity = () => {
  const [quanity, setQuanity] = useState(1);

  const decreaseTicketQuantity = () => {
    if (quanity <= 1) return;

    setQuanity(quanity - 1);
  };

  const increaseTicketQuantity = (pkg) => {
    console.log(pkg?.quantity * 1, pkg);
    // package quanity is 0 means we can add unlimited product
    if (pkg?.quantity === 0) {
      setQuanity(quanity + 1);
    } else {
      if (quanity >= pkg?.quantity * 1) return;
      setQuanity(quanity + 1);
    }
  };

  return [quanity, increaseTicketQuantity, decreaseTicketQuantity];
};
