import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useMyContext } from '../../../contexts/StateHolder';

import * as classes from './UpcomingEvent.module.css';
import { useLocation, useParams } from 'react-router-dom';
import {
  checkTicketNumber,
  getSingleEvent,
  purchasePackage,
} from '../../../scripts/dataHandlers';
import AssetVideoPlayer from '../../VideoPlayer/AssetVideoPlayer';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import queryString from 'query-string';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../../Shared/Header/Header';
import Button from '../../../Shared/Button/Button';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fi';
import LiveEvents from '../LiveEvents/LiveEvents';
import EventsRoomDescription from './EventsRoomDescription';
import { momentDate } from '../../../scripts/utils';
import { useCallback } from 'react';
import CompletedEventWithRecording from './CompletedEventWithRecording';

export const BuyTickets = ({
  routes,
  futureEvents,
  singleEvent,
  setTicketAccessGranted,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const [ticketNum, setTicketNum] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['tiAcc']);

  const buyNewTicket = () => {
    setCookie(
      'packageReturn',
      { returnPath: location.pathname },
      { path: '/' }
    );
    removeCookie('packageType', {
      path: '/',
    });
    setTimeout(() => {
      setCookie('packageType', 'ticket', {
        path: '/',
      });
      return history.push(`/${routes.packages}/asset/${singleEvent.eventId}`);
    }, 100);
  };

  const checkTicketHandler = async () => {
    const res = await checkTicketNumber(singleEvent.eventId, ticketNum);
    if (res.data.status === 'ok') {
      // somehow we will get period = the hour which ticket will be active and bought time of the ticket

      let currentTime = Date.now();
      console.log('res.data', res.data);

      // purchase time from api

      let purchaseTime = res?.data?.ts;

      function addDays(date, days) {
        var result = new Date(Number(date));
        result.setDate(result.getDate() + days);
        return result.getTime();
      }
      // adding 1 day to purchase time is expiry time
      // we get in hour so changing in days
      let daysToBeAdded = res?.data?.period / 24;

      // adding 1 day to purchase time is expiry time

      let expiryTime = addDays(purchaseTime, daysToBeAdded);

      console.log(
        'expiryTime',
        expiryTime,
        purchaseTime,
        expiryTime - currentTime
      );

      if (currentTime > expiryTime) {
        // ticket has expired no access granted
        setTicketAccessGranted(false);
        removeCookie('tiAcc', { path: '/' });
        return toast.error('Ticket Has Expired.');
      } else {
        // let remaining_time = new Date(expiryTime - currentTime).getHours();

        let remaining_time =
          (new Date(expiryTime).getTime() - new Date(currentTime).getTime()) /
          1000;

        // console.log('remaining_timeq', remaining_time);
        // remaining_time /= 60 * 60;
        // remaining_time = Math.round(remaining_time);

        console.log('remaining_time', remaining_time);
        console.log(
          'new Date(Date.now() + 1000 * remaining_time)',
          new Date(Date.now() + 1000 * remaining_time)
        );

        setTicketAccessGranted(true);
        setCookie('tiAcc', ticketNum, {
          path: '/',
          expires: new Date(Date.now() + 1000 * Math.ceil(remaining_time)),
        });
      }
    } else if (res.data.status === 'error') {
      setTicketNum('');
      removeCookie('tiAcc', { path: '/' });

      if (futureEvents) {
        return toast.error(`${t('eventsCategory.eventNotStarted')}`, {
          autoClose: 9000,
          position: 'top-right',
          closeOnClick: true,
          draggable: true,

          hideProgressBar: true,

          theme: 'colored',
        });
      }
      return toast.error(`${t('eventsCategory.invalidTicket')}`, {
        autoClose: 9000,
        position: 'top-right',
        closeOnClick: true,
        draggable: true,

        hideProgressBar: true,

        theme: 'colored',
      });
    }
  };

  return (
    <>
      <ToastContainer />

      <div className={classes.buyTicket}>
        <div className={classes.buyTickets_info}>
          <div
            style={{ fontWeight: 800, color: 'white', width: '200%' }}
            className='font-500'
          >
            {t('eventsCategory.enterTicket')}
          </div>

          <div className={classes.buyTickets_info_input}>
            <input
              type='text'
              value={ticketNum}
              onChange={(e) => setTicketNum(e.target.value)}
              placeholder={`${t('eventsCategory.enterYourTicket')}`}
            />
          </div>
        </div>

        <div className={classes.buyTickets_info_button}>
          <Button onClick={checkTicketHandler}>
            {' '}
            {t('eventsCategory.watch')}
          </Button>

          <Button onClick={buyNewTicket} inverse={true}>
            {t('eventsCategory.buyTickets')}
          </Button>
        </div>
      </div>
    </>
  );
};

export const eventBackground = (el) => {
  console.log('el1', el);
  return (
    <div
      className='overlay_blur bannerImage'
      style={{
        position: 'relative',
        // width: '40%',
        margin: '0 auto',
        height: '100%',
        width: '100%',
        maxWidth: '700px',
        margin: '0 auto',
      }}
    >
      {' '}
      <div
        className={`${classes.EventSecondary} ${classes.EventSecondary_backgroundImage} `}
        style={{
          backgroundImage: `url(${el?.thumbnailImage}) `,
        }}
      ></div>
      {/* <img
        src={el?.thumbnailImage}
        style={{
          objectFit: 'cover',
          objectPosition: '50% 50%',
          justifySelf: 'center',
          width: '100%',
          maxHeight: '80vh',
        }}
      /> */}
    </div>
  );
};

export default function UpcomingEvent(props) {
  console.log(props);
  const { t } = useTranslation();

  const [singleEvent, setSingleEvent] = useState(null);
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [recordingAssetId, setRecordingAssetId] = useState(null);

  const [isLiveEvents, setIsLiveEvents] = useState(false);
  const { singleEventItem, setTicketQuantity, organizationId } = useMyContext();
  const { orgId, eventId } = useParams();

  // if the events requires ticket to grant access

  const [ticketAccess, setTicketAccess] = useState(false);
  const [ticketAccessGranted, setTicketAccessGranted] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['tiAcc']);

  // this useEffect will only run when user is redirected from purchase page to here

  console.warn('loading11111', loading);

  useEffect(() => {
    let params = queryString.parse(window.location.search);

    async function purchasePackageData() {
      const purchaseResponse = await purchasePackage(
        cookies?.userData?.userToken,
        params
      );
      console.log(purchaseResponse, 'purchaseResponse');
      let ticket;

      // expiry time is 24 hour from purchase for ticket
      let expiryTime = new Date(Date.now() + 3600 * 1000 * 24);

      console.log(
        purchaseResponse,
        purchaseResponse.message,
        purchaseResponse.errorCode === 27,
        purchaseResponse.errorCode
      );
      if (
        purchaseResponse?.message.includes('successful') ||
        purchaseResponse?.status === 'ok'
      ) {
        setTicketAccessGranted(true);
        ticket = purchaseResponse?.tickets[0];
        console.log('ticketnew', ticket);

        toast.success('Purchase Successful');
        toast.success(`Your Ticket Number is ${ticket}`);
        ticket &&
          setCookie('tiAcc', ticket, { path: '/', expires: expiryTime });
      } else if (
        purchaseResponse?.errorCode === 27 ||
        purchaseResponse?.message.includes('refresh call for order')

        // this occours when user refrsh the page
      ) {
        ticket = purchaseResponse?.tickets[0];
        console.log('ticketrefreh', ticket);
        ticket &&
          setCookie('tiAcc', ticket, { path: '/', expires: expiryTime });
      } else {
      }

      setLoading(false);
    }
    console.log(
      params,
      'params',
      params?.RETURN_CODE,
      params?.RETURN_CODE === true
    );

    // for dpo payment
    if (params) {
      if (params?.paymentMethod === '7') {
        purchasePackageData();
      } else if (params?.RETURN_CODE === '0') {
        purchasePackageData();
      } else if (
        (params?.PAYMENT_METHOD === '1') &
        (params.method === 'paytrail')
      ) {
        // thus us for paytrail
        console.log('paytrail', ' paytrail');
        purchasePackageData();
      } else {
        setLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // checks ticket

  console.log('organizationId', organizationId);
  useEffect(() => {
    if (organizationId) {
      if (singleEventItem?.eventId) {
        setSingleEvent(singleEventItem);
        setDefaultLanguage(singleEventItem.defaultLanguage);
        setLoading(false);
        if (singleEventItem?.accessControls?.length > 0) {
          const ticketExists = singleEventItem?.accessControls.find(
            (el) => el.typeName === 'Ticket'
          );
          console.log('ticketExists', ticketExists);
          if (ticketExists) {
            // not granted access for viewing event
            setTicketAccess(true);
          } else {
            setTicketAccessGranted(true);
            setTicketAccess(false);
          }
        } else {
          setTicketAccess(false);
          setTicketAccessGranted(true);
        }
      } else {
        const getData = async () => {
          const res = await getSingleEvent(organizationId, eventId);
          console.log('res', res);
          setSingleEvent(res?.event);
          setDefaultLanguage(res?.event?.defaultLanguage);
          if (res?.event?.accessControls?.length > 0) {
            const ticketExists = res?.event?.accessControls.find(
              (el) => el.typeName === 'Ticket'
            );
            console.log('ticketExists', ticketExists);

            if (ticketExists) {
              setTicketAccess(true);
            } else {
              setTicketAccessGranted(true);
              setTicketAccess(false);
            }
          } else {
            setTicketAccess(false);
            setTicketAccessGranted(true);
          }

          setLoading(false);
        };
        getData();
      }
    }
    return () => {
      setTicketQuantity(1);
    };
  }, [singleEventItem?.eventId, organizationId]);

  // console.log(ticketAccessGranted, ticketAccess, 'tavailable');
  const checkAcessFromCookie = useCallback(async () => {
    const res = await checkTicketNumber(singleEvent?.eventId, cookies?.tiAcc);
    console.log('resticket', res);
    if (res.data.status === 'ok') {
      let purchaseTime = res?.data?.ts;

      function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result.getTime();
      }

      // adding 1 day to purchase time is expiry time
      // we get in hour so changing in days

      console.log('res?.data?.period', res?.data?.period);
      let daysToBeAdded = res?.data?.period / 24;

      let expiryTime = addDays(purchaseTime, daysToBeAdded);
      let currentTime = Date.now();

      if (currentTime > expiryTime) {
        // ticket has expired no access granted
        setTicketAccessGranted(false);
        removeCookie('tiAcc', { path: '/' });
        return toast.error('Ticket Has Expired.');
      } else {
        let remaining_time =
          (new Date(expiryTime).getTime() - new Date(currentTime).getTime()) /
          1000;

        console.log('remaining_time1', remaining_time);

        setTicketAccessGranted(true);
        setCookie('tiAcc', cookies?.tiAcc, {
          path: '/',
          expires: new Date(Date.now() + 1000 * Math.ceil(remaining_time)),
        });
      }
    } else {
      setTicketAccessGranted(false);
      removeCookie('tiAcc', { path: '/' });
    }
  }, [
    singleEvent?.eventId,
    cookies?.tiAcc,
    setTicketAccessGranted,
    setCookie,
    removeCookie,
  ]);

  useEffect(() => {
    // we will have ticket in our cookie all the time && loading false means if the ticket was just bought here and redirected heree it means that function will run first
    if (singleEvent?.eventId && cookies?.tiAcc) {
      checkAcessFromCookie();
    }
  }, [cookies?.tiAcc, singleEvent?.eventId]);

  const CompletedEventsWithoutRecording = () => {
    return (
      <div className={classes.EventTertairy}>
        <div className={classes.EventPrimary}>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              gap: '50px',
              width: '100%',
              maxWidth: '700px',
            }}
          >
            {singleEvent?.thumbnailImage && eventBackground(singleEvent)}
          </div>

          <div
            className={classes.EventPrimary_description}
            dangerouslySetInnerHTML={{
              __html: singleEvent.description[defaultLanguage],
            }}
          ></div>
        </div>
      </div>
    );
  };

  const CompletedEvents = ({ routes }) => {
    console.log('singleEvent', singleEvent);
    useEffect(() => {
      if (singleEvent?.rooms?.length > 0) {
        setRecordingAssetId(singleEvent.rooms[0].recordings[0]?.assetId);
        setIsLiveEvents(false);
      }

      let currentTime = Date.now();
      // time is second, Dat.now is ms so multiply by 1000
      let totalEndTime;

      if (singleEvent?.startTimePublic) {
        totalEndTime =
          singleEvent?.startTimePublic + singleEvent?.durationPublic * 1000;
      } else {
        totalEndTime = singleEvent?.startTime + singleEvent?.duration * 1000;
      }

      if (
        (currentTime >= singleEvent.startTimePublic
          ? singleEvent.startTimePublic
          : singleEvent.startTime) &&
        currentTime < totalEndTime
      ) {
        console.log(
          'startTime',
          singleEvent?.startTimePublic,
          totalEndTime,
          currentTime
        );
        setIsLiveEvents(true);
      }
    }, []);
    console.log('isLiveEvents', isLiveEvents, singleEvent, recordingAssetId);
    if (isLiveEvents)
      return (
        <LiveEvents
          routes={routes}
          ticketAccess={ticketAccess}
          ticketAccessGranted={ticketAccessGranted}
          singleEvent={singleEvent}
          defaultLanguage={defaultLanguage}
          setTicketAccessGranted={setTicketAccessGranted}
        />
      );

    return recordingAssetId ? (
      <CompletedEventWithRecording
        routes={routes}
        ticketAccess={ticketAccess}
        ticketAccessGranted={ticketAccessGranted}
        singleEvent={singleEvent}
        defaultLanguage={defaultLanguage}
        setTicketAccessGranted={setTicketAccessGranted}
        loading={loading}
        recordingAssetId={recordingAssetId}
        isLiveEvents={isLiveEvents}
        setLoading={setLoading}
      />
    ) : (
      <CompletedEventsWithoutRecording routes={routes} />
    );
  };

  const FutureEvents = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
    routes,
  }) => {
    console.log('singleEvent', singleEvent);
    return (
      <>
        <div className={classes.EventSecondary}>
          <div
            className={`${classes.EventTertairy} `}
            style={{
              position: 'relative',
              display: 'flex',
              gap: '50px',
              marginTop: '50px',
              width: '100%',
              maxWidth: '700px',
              margin: '0 auto',
            }}
          >
            {singleEvent?.thumbnailImage && eventBackground(singleEvent)}
            <div>
              <Header
                extraClassname={true}
                title={
                  singleEvent?.publicName[defaultLanguage] ||
                  singleEvent?.name[defaultLanguage]
                }
              />
              <div
                className={`${classes.EventPrimary_countdown} font-800`}
                style={{
                  opacity: '1 !important',
                  // position: 'absolute',
                  // top: '75px',
                }}
              >
                <div className={classes.EventPrimary_countdown_description}>
                  {' '}
                  <span>{zeroPad(days)}</span>
                  <p className='font-300' style={{ color: 'white' }}>
                    {t('eventsCategory.days')}
                  </p>
                </div>
                <div className={classes.EventPrimary_countdown_description}>
                  <span>{zeroPad(hours)}</span>
                  <p className='font-300' style={{ color: 'white' }}>
                    {t('eventsCategory.hours')}
                  </p>
                </div>
                <div className={classes.EventPrimary_countdown_description}>
                  <span>{zeroPad(minutes)}</span>

                  <p className='font-300' style={{ color: 'white' }}>
                    {t('eventsCategory.minutes')}
                  </p>
                </div>
                <div className={classes.EventPrimary_countdown_description}>
                  <span>{zeroPad(seconds)}</span>

                  <p className='font-300' style={{ color: 'white' }}>
                    {t('eventsCategory.seconds')}
                  </p>
                </div>
              </div>

              {ticketAccess && !ticketAccessGranted && (
                <BuyTickets
                  routes={routes}
                  futureEvents={true}
                  singleEvent={singleEvent}
                  setTicketAccessGranted={setTicketAccessGranted}
                  ticketAccessGranted={ticketAccessGranted}
                />
              )}

              <div
                className={classes.EventPrimary_description}
                dangerouslySetInnerHTML={{
                  __html: singleEvent.description[defaultLanguage],
                }}
              ></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // This is a conditional Renndering. CompletionList will be called when the count down is over
  const Renderer = ({ days, hours, minutes, seconds, completed }) => {
    console.log(days, 'days');
    if (completed) {
      return <CompletedEvents routes={props.routes} />;
    } else {
      return (
        <>
          <FutureEvents
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            completed={completed}
            routes={props.routes}
          />
        </>
      );
    }
  };
  return (
    <>
      <ToastContainer />
      <div>
        {!loading && singleEvent && (
          <>
            <Countdown
              date={
                singleEvent?.startTimePublic
                  ? singleEvent?.startTimePublic
                  : singleEvent?.startTime
              }
              renderer={Renderer}
            />
          </>
        )}
      </div>
    </>
  );
}
