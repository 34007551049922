import React, { useState } from 'react';
import Header from '../../../Shared/Header/Header';
import * as classes from './UpcomingEvent.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDuration, momentDate } from '../../../scripts/utils';
import moment from 'moment';
import RenderFolderNames from '../../Details/DetailsFolderNames';
import { useTranslation } from 'react-i18next';
import { BuyTickets, eventBackground } from './UpcomingEvent';
import { getImageByKey } from '../../../scripts/getImageByKey';
import Button from '../../../Shared/Button/Button';
import { useForm } from '../../../Shared/Hooks/form-hook';
import { voucherPurchase } from '../../../scripts/dataHandlers';
import { useMyContext } from '../../../contexts/StateHolder';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { toast, ToastContainer } from 'react-toastify';

export default function EventsRoomDescription({
  ticketAccessGranted,
  singleEvent,
  ticketAccess,
  liveEvent,
  defaultLanguage,
  setTicketAccessGranted,
  routes,
  futureEvents,
}) {
  const { t } = useTranslation();
  const { language } = useMyContext();
  const history = useHistory();
  const [ticketNum, setTicketNum] = useState('');

  const [cookies, setCookie, removeCookie] = useCookies(['']);

  // if access is granted we show room info else event info
  console.log(singleEvent);

  const singleItem = ticketAccessGranted ? singleEvent : singleEvent;
  const voucherButtonAction = async () => {
    console.log(cookies, cookies?.ue);
    const voucherPurchaseResponse = await voucherPurchase(
      'random@email.com',
      language,
      177994676,
      180291963,
      ticketNum,
      1,
      'random@email.com'
    );

    //console.log("VPR:", voucherPurchaseResponse);

    if (voucherPurchaseResponse.data.status === 'ok') {
      // we have to set the event tickets in cookie
      // expiry time is 24 hour from purchase for ticket
      let expiryTime = new Date(Date.now() + 3600 * 1000 * 24);

      setCookie('tiAcc', voucherPurchaseResponse?.data?.tickets[0], {
        path: '/',
        expires: expiryTime,
      });
      if (cookies?.packageType === 'ticket') {
        history.push(`/event/177994676/178341745`);
      }

      // moveToReceipt();
    } else {
      console.log('Invalid Voucher');
      toast.error('Invalid Voucher');

      // toast.error('voucherPurchaseResponse.data.messsage');
    }
  };
  return (
    <>
      <ToastContainer />
      <div className={classes.EventTertairy}>
        {/* <div
        style={{
          padding: '50px 0 0 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button to='/packages/177994676/179112979'>Donate</Button>
      </div> */}

        <div className={classes.EventPrimary}>
          {ticketAccess && !ticketAccessGranted && (
            <>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  maxWidth: '700px',
                  margin: '0 auto',
                }}
              >
                {singleEvent?.thumbnailImage && eventBackground(singleEvent)}

                <div
                  className={`${classes.EventPrimary_countdown} font-600`}
                  style={{
                    opacity: '1 !important',
                    position: 'absolute',
                    top: '75px',
                    color: 'white',
                  }}
                ></div>
              </div>
            </>
          )}

          <div>
            {/* <Header
            extraClassname={true}
            title={
              singleEvent?.publicName[defaultLanguage] ||
              singleEvent?.name[defaultLanguage]
            }
          /> */}
            <div
              style={{ width: '100%', margin: '0 auto', display: 'flex' }}
              className={classes.imageBanner}
            >
              <img
                src={getImageByKey('titleImage')}
                alt='Beginning Of New Era'
                style={{
                  height: '100%',
                  maxWidth: '100%',
                  maxHeight: '100%',

                  margin: '0 auto',
                  flex: 0,

                  marginBottom: '30px',
                }}
              />
            </div>
            {ticketAccess && !ticketAccessGranted && (
              <BuyTickets
                routes={routes}
                futureEvents={futureEvents}
                singleEvent={singleEvent}
                setTicketAccessGranted={setTicketAccessGranted}
              />
            )}

            {ticketAccess && !ticketAccessGranted && (
              <div
                className={classes.buyTickets_info}
                style={{ marginTop: '20px' }}
              >
                <div
                  style={{ fontWeight: 800, color: 'white' }}
                  className='font-500'
                >
                  Watch with voucher
                </div>

                <div
                  className={`${classes.buyTickets_info_input} ${classes.voucherView}`}
                  style={{ alignItems: 'center' }}
                >
                  <input
                    type='text'
                    placeholder={`Enter your  voucher number`}
                    style={{ minWidth: '250px' }}
                    value={ticketNum}
                    onChange={(e) => setTicketNum(e.target.value)}
                  />

                  <Button
                    style={{
                      flex: 0,
                      height: '50px',
                      minHeight: '50px',
                      padding: '12px 30px',
                    }}
                    className={classes.voucherButton}
                    onClick={voucherButtonAction}
                  >
                    Watch
                  </Button>
                  <div></div>
                </div>
              </div>
            )}

            <div
              className={classes.EventPrimary_description}
              dangerouslySetInnerHTML={{
                __html: singleItem?.description[defaultLanguage],
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
