//import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useMyContext } from '../../../contexts/StateHolder';
import { convertCurrency } from '../../../scripts/utils';
import * as classes from './SelectedPackage.module.css';

//just prints selected PAckage data. It is used in both receipt and paymentMethods views
const SelectedPackage = () => {
  const { selectedPackage, ticketQuantity } = useMyContext();

  console.log('selectedPackage', selectedPackage);

  const { t } = useTranslation();

  if (Object.keys(selectedPackage).length === 0) {
    return null;
  } else {
    const taxPerc = selectedPackage.taxRate;

    return (
      <div className={classes.selectedPackage}>
        <div
          className={`${classes.selectedPackagePrimary} ${classes.selectedPackagePrimaryHeading} font-600`}
        >
          <div className={classes.selectedPackageList_name}>Product Name</div>
          <div className={classes.selectedPackageList}>Price</div>

          <div className={classes.selectedPackageList}>Vat</div>

          <div className={classes.selectedPackageList}>Quantity</div>

          <div className={classes.selectedPackageList}>Total Price</div>
        </div>
        <div className={`${classes.selectedPackagePrimary} font-400`}>
          <div className={classes.selectedPackageList_name}>
            {selectedPackage.name}
          </div>
          <div className={classes.selectedPackageList}>
            {selectedPackage.price} {convertCurrency(selectedPackage.currency)}
          </div>

          <div className={classes.selectedPackageList}>{taxPerc}%</div>

          <div className={classes.selectedPackageList}>{ticketQuantity}</div>

          <div className={classes.selectedPackageList}>
            {ticketQuantity * selectedPackage.price}{' '}
            {convertCurrency(selectedPackage.currency)}
          </div>
        </div>
      </div>
    );
  }
};

export default SelectedPackage;
