import * as classes from './PrivacyPolicy.module.css';

const PrivacyPolicy = (props) => {
  return (
    <div className={`${classes.tosContainer} font-300`}>
      <div
        className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
      >
        Beginning of a New Era Privacy Policy
      </div>
      <p className={`${classes.tosMainTitle_p} font-200 `}>
        Effective date: June 18, 2022
      </p>
      <br />
      <hr />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Sunlight Creations, marketing name New Era (“New Era“, "us", "we", or
        "our") operates the Beginning of a New Era film stream service (the
        "Stream service“). <br />
        This page informs you of our policies regarding the collection, use, and
        disclosure of personal data when you use our Service and the choices you
        have associated with that data. <br /> By using the Service, you agree
        to the collection and use of information in accordance with this policy.
        Unless otherwise defined in this Privacy Policy, terms used in this
        Privacy Policy have the same meanings as in our Terms and Conditions.{' '}
        <br />
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400 `}>
        Information Collection And Use
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We collect several different types of information for various purposes
        to provide and improve our Service to you and control the usage of our
        Customer's Icareus Suite accounts. <br />
      </p>
      <br />
      <div className={`${classes.tosTitle} font-300 `}>
        Types of Data Collected
      </div>
      <div className={`${classes.tosTitle} font-200 `}>Personal Data </div>
      <p className={`${classes.tosMainTitle_p} `}>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you ("Personal Data"). Personally identifiable information may
        include, but is not limited to:{' '}
      </p>
      <ul>Email address</ul>
      <ul> First name and last name</ul>
      <ul> Phone number</ul>
      <ul> Address, State, Province, ZIP/Postal code, City/County</ul>
      <ul> Cookies</ul>
      <ul> Usage Data and Log</ul>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-200 `}>Usage Data </div>
      <p className={`${classes.tosMainTitle_p} `}>
        When you access the Service by Web browser or through a mobile
        application (Android or iOS app), we may collect certain information
        automatically, including, but not limited to, the type of mobile device
        you use, your mobile device unique ID, the IP address of your mobile
        device, your mobile operating system, the type of mobile Internet
        browser you use, unique device identifiers and other diagnostic data
        ("Usage Data").
      </p>
      <br />
      <div className={`${classes.tosTitle} font-200 `}>
        Tracking & Cookies Data
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We use cookies and similar tracking technologies to track the activity
        on our Service and hold certain information. Cookies are files with
        small amount of data which may include an anonymous unique identifier.
        Cookies are sent to your browser from a website and stored on your
        device. Tracking technologies also used are beacons, tags, and scripts
        to collect and track information and to improve and analyze our Service.{' '}
        <br />
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service. <br /> Examples of
        Cookies we use: <br />
        <ul>Session Cookies: We use Session Cookies to operate our Service</ul>
        <ul>
          Preference Cookies: We use Preference Cookies to remember your
          preferences and various settings.
        </ul>
        <ul>Security Cookies: We use Security Cookies for security purposes</ul>
      </p>
      <div className={`${classes.tosTitle} font-400 `}>Use of Data</div>
      <p className={`${classes.tosMainTitle_p} `}>
        New Era uses the collected data for various purposes:
      </p>
      <br />
      <ul> To provide and maintain the Service</ul>
      <ul> To notify you about changes to our Service</ul>
      <ul>
        To allow you to participate in interactive features of our Service when
        you choose to do so
      </ul>
      <ul> To provide customer care and support</ul>
      <ul>
        To provide analysis or valuable information so that we can improve the
        Service
      </ul>
      <ul> To monitor the usage of the Service</ul>
      <ul> To detect, prevent and address technical issues</ul>
      <ul>
        {' '}
        To use for commercial purposes, including (selling to) third party usage
      </ul>
      <br /> <br />
      <div className={`${classes.tosTitle} font-400 `}>Transfer Of Data</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Your information, including Personal Data, may be transferred to — and
        maintained on — computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ than those from your jurisdiction. <br /> Your consent
        to this Privacy Policy followed by your submission of such information
        represents your agreement to that transfer. <br />
        New Era will take all steps reasonably necessary to ensure that your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of your Personal Data will take place to an organisation or
        a country unless there are adequate controls in place including the
        security of your data and other personal information. <br />
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400 `}>Disclosure Of Data</div>
      <div className={`${classes.tosTitle} font-300 `}>Legal Requirements</div>
      <p className={`${classes.tosMainTitle_p} `}>
        New Era may disclose your Personal Data in the good faith belief that
        such action is necessary to:
        <ul>To comply with a legal obligation</ul>
        <ul>To protect and defend the rights or property of New Era</ul>
        <ul>
          {' '}
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </ul>
        <ul>
          To protect the personal safety of users of the Service or the public
        </ul>
        <ul>To protect against legal liability</ul>
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400 `}>Security Of Data</div>
      <p className={`${classes.tosMainTitle_p} `}>
        The security of your data is important to us, but remember that no
        method of transmission over the Internet, or method of electronic
        storage is 100% secure. While we strive to use commercially acceptable
        means to protect your Personal Data, we cannot guarantee its absolute
        security.
        <br />
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>Service Providers</div>
      <p className={`${classes.tosMainTitle_p} `}>
        We may employ third party companies and individuals to facilitate our
        Service ("Service Providers"), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our
        Service is used. <br />
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-300`}>Analytics</div>
      <p className={`${classes.tosMainTitle_p} `}>
        We may use third-party Service Providers to monitor and analyze the use
        of our Service. <br />
      </p>
      <br />
      <div className={`${classes.tosTitle} font-200`}>
        <ul>Google Analytics</ul>
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Google Analytics is a web analytics service offered by Google that
        tracks and reports website traffic. Google uses the data collected to
        track and monitor the use of our Service. This data is shared with other
        Google services. Google may use the collected data to contextualize and
        personalize the ads of its own advertising network. <br />
        You may opt-out of certain Google Analytics features through your mobile
        device settings, such as your device advertising settings or by
        following the instructions provided by Google in their Privacy Policy:
        https://policies.google.com/privacy?hl=en <br />
        For more information on the privacy practices of Google, please visit
        the Google Privacy & Terms web page:
        https://policies.google.com/privacy?hl=en <br />
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Links To Other Sites{' '}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Our Service may contain links to other sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        of every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third party sites or services.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Changes To This Privacy Policy{' '}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. 
        <br />
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update the "effective date"
        at the top of this Privacy Policy. 
        <br />
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
        <br />
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-400`}>Contact Us </div>
      <p className={`${classes.tosMainTitle_p} `}>
        If you have any questions about this Privacy Policy, please contact us:
        <br />
      </p>{' '}
      <ul> By email: newerathemovie@gmail.com</ul>
      <ul> By visiting this page on our website: www.newerathemovie.com</ul>
      <br />
    </div>
  );
};

export default PrivacyPolicy;
