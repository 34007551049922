import * as classes from './TOS.module.css';
//import { useTranslation } from 'react-i18next';
import { BrowserRouterasRouter } from 'react-router-dom';

const TOS = (props) => {
  //const { t } = useTranslation();

  return (
    <div
      className={`${classes.tosContainer} font-300`}
      style={props?.styles?.tosContaine}
    >
      <div
        className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
      >
        Beginning of a New Era Terms of Use
      </div>
      <br />
      <hr />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Beginning of a New Era online service provides a service that allows our
        customers to access Beginning of a New Era - film online as well as
        other videos streamed over the Internet to certain Internet -connected
        computers and other devices. <br /> New Era online service is provided
        to you by Sunlight Creations (Business-id1742744-9), a Finnish limited
        liability company. These Terms of Use govern your use of our service. As
        used in these Terms of Use, “New Era online service", "our service" or
        "the service" means the service provided by Icareus for discovering and
        watching New Era film online service, including all features and
        functionalities, the website, and user interfaces, as well as all
        content and software associated with our service. <br /> Contact us{' '}
        <br />
        E-mail: newerathemovie@gmail.com
      </p>
      <br />
      <div className={`${classes.tosMainTitle}  font-400 `}>Billing </div>
      <div className={`${classes.tosMainTitle}  font-300 `}>
        Ticket Sales / Transactional VOD{' '}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        The fee to watch the movie and any other charges you may incur in
        connection with your use of the service, such as taxes and possible
        transaction fees, will be charged on the purchase transaction. <br />
        The fee to watch the digital video content delivered over internet and
        any other charges you may incur in connection with your use of the
        service, such as taxes and possible transaction fees, will be charged on
        the purchase transaction. By making orders for digital video content
        delivered over internet from Sunlight Creations you agree that the
        content will be delivered to you before your legal cancellation time of
        14 days and you agree that your cancellation right is revoked.
        <br />
      </p>
      <br />
      <div className={`${classes.tosMainTitle}  font-300 `}>
        Payment Methods{' '}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Paytrail (Paytrail Oy, business-id 2122839-7 ) is the payment
        facilitator of the New Era online service. Paytrail is a payment
        facilitator authorized by the Financial Supervisory Authority of
        Finland. The payment process is conducted in the online payment service
        of the Paytrail is shown as the payment received in the bank account
        listing and in the invoice. Paying with Paytrail is safe. All
        information is exchanged through secured connections. The trade happens
        between the online customer and the New Era online service. New Era
        online service is responsible for all obligations related to the trade.
        Read more about Paytrail: www.paytrail.fi <br />
        With Paytrail you can pay your order by an internet banking account, a
        payment card (credit/debit). The following methods of payment are
        supported: Osuuspankki, Nordea, Danske Bank, Oma Säästöpankki,
        Säästöpankki, Aktia, Paikallisosuuspankit, S-Pankki, Handelsbanken,
        Ålandsbanken, Visa-, Visa Debit-, Visa Electron-, MasterCard- and Debit
        MasterCard payment cards. <br />
        MobilePay: You can pay with your MobilePay wallet if you have allowed
        online payments in the settings of the MobilePay application. Payment
        via MobilePay takes place directly from the payment card linked to
        MobilePay. If charging the payment from the linked card fails, MobilePay
        can not be used in the online shop. <br />
      </p>
      <br />
      <div className={`${classes.tosMainTitle}  font-300 `}>
        Payment Returns
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        All Payment returns are done using the same payment method as the
        original payment was done.
      </p>
      <br /> <br />
      <div className={`${classes.tosMainTitle}  font-300 `}>
        Contact Paytrail
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Paytrail Plc | Innova 2, Lutakonaukio 7 | 40100 Jyväskylä | +358 20 718
        1820
      </p>
      <br />
      <div className={`${classes.tosMainTitle}  font-400 `}>
        New Era Online Service
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        You must be 13 years of age, or the age of majority in your province,
        territory or country, to become a member of the New Era online service.
        Minors may only use the service under the supervision of an adult.{' '}
        <br />
        The New Era online service and any content viewed through the service
        are for your personal and for non-commercial use only. During your New
        Era online service subscription or transactional movie access we grant
        you a limited, non-exclusive, non-transferable, license to access the
        New Era online service and view New Era online service content. Except
        for the foregoing limited license, no right, title or interest shall be
        transferred to you. You agree not to use the service for public
        performances. <br />
        You may view the New Era online service content primarily within the
        country in which you have established your account and only in
        geographic locations where we offer our service. <br />
        New Era regularly makes changes to the service, including the content
        library. In addition, we continually test various aspects of our
        service, including our website, user interfaces, promotional features
        and availability of New Era online service content. <br />
        You agree to use the New Era online service, including all features and
        functionalities associated therewith, in accordance with all applicable
        laws, rules and regulations, or other restrictions on use of the service
        or content therein. You agree not to archive, reproduce, distribute,
        modify, display, perform, publish, license, create derivative works
        from, offer for sale, or use (except as explicitly authorized in these
        Terms of Use) content and information contained on or obtained from or
        through the New Era film online service. You also agree not to:
        circumvent, remove, alter, deactivate, degrade or thwart any of the
        content protections in the New Era online service; use any robot,
        spider, scraper or other automated means to access the New Era online
        service; decompile, reverse engineer or disassemble any software or
        other products or processes accessible through the New Era online
        service; insert any code or product or manipulate the content of the New
        Era online service in any way; or use any data mining, data gathering or
        extraction method. In addition, you agree not to upload, post, e-mail or
        otherwise send or transmit any material designed to interrupt, destroy
        or limit the functionality of any computer software or hardware or
        telecommunications equipment associated with the New Era online service,
        including any software viruses or any other computer code, files or
        programs. We may terminate or restrict your use of our service if you
        violate these Terms of Use or are engaged in illegal or fraudulent use
        of the service. <br /> <br /> The quality of the display of the New Era
        online service content may vary from device to device, and may be
        affected by a variety of factors, such as your location, the bandwidth
        available through and/or speed of your Internet connection. You are
        responsible for all Internet access charges. Please check with your
        Internet provider for information on possible Internet data usage
        charges. The time it takes to begin watching New Era online service
        content will vary based on a number of factors, including your location,
        available bandwidth at the time, the content you have selected and the
        configuration of your New Era online service ready device. <br /> <br />
      </p>
      <br />
      <div className={`${classes.tosMainTitle}  font-300 `}>Governing Law</div>
      <p className={`${classes.tosMainTitle_p} `}>
        These Terms of Use shall be governed by and construed in accordance with
        the laws of Finland. These terms will not limit any consumer protection
        rights that you may be entitled to under the mandatory laws of your
        country of residence.
      </p>
      <br />
      <div className={`${classes.tosMainTitle}  font-300 `}>
        Unsolicited Materials{' '}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        New Era does not accept unsolicited materials or ideas for New Era film
        online service content and is not responsible for the similarity of any
        of its content or programming in any media to materials or ideas
        transmitted to New Era online service.
        <br />
      </p>
      <br />
      <div className={`${classes.tosMainTitle}  font-300 `}>
        Customer Support{' '}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        To find more information about our service and its features or if you
        need assistance with your account, please contact our Customer Service
        at newerathemovie@gmail.com. In the event of any conflict between these
        Terms of Use and information provided by Customer Support or other
        portions of our website, these Terms of Use will control.
      </p>
      <br />
      <div className={`${classes.tosMainTitle}  font-300 `}>Survival</div>
      <p className={`${classes.tosMainTitle_p} `}>
        If any provision or provisions of these Terms of Use shall be held to be
        invalid, illegal, or unenforceable, the validity, legality and
        enforceability of the remaining provisions shall remain in full force
        and effect.
      </p>
      <br />
      <div className={`${classes.tosMainTitle}  font-300 `}>
        Changes to Terms of Use
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        <br />
        New Era may, from time to time, change these Terms of Use. We will
        notify you at least 30 days before these new Terms of Use apply to you.
      </p>
      <br />
      <div className={`${classes.tosMainTitle}  font-300 `}>
        Electronic Communications
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We will send you information relating to your account (e.g. payment
        authorizations, invoices, changes in password or Payment Method,
        confirmation messages, notices) in electronic form only, for example via
        emails to your email address provided during registration.
        <br />
        <br />
        Last updated: June 18, 2022.
      </p>
      <br />
      <br />
    </div>
  );
};

export default TOS;
