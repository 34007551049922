import { useTranslation } from 'react-i18next';
import { convertCurrency } from '../../../scripts/utils';
import * as classes from './PackageComponent.module.css';
import { useMyContext } from '../../../contexts/StateHolder';
import { useEffect, useState } from 'react';

import { useCookies } from 'react-cookie';
import Button from '../../../Shared/Button/Button';
import { useForm } from '../../../Shared/Hooks/form-hook';
import { useQuantity } from '../../../Shared/Hooks/formQuantity-hook';

//shows single package information. Doesn't handle ticket packages or f-liiga single event purchase packages yet.
const PackageComponent = ({
  pkg,
  buyButtonAction,
  userPackages,
  isValid,
  email,
}) => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(['ue']);

  console.log('pkgname', pkg);

  const [onlyNumberSelected, setOnlyNumberSelected] = useState(false);

  const { user, userEmail, setUserEmail, setTicketQuantity } = useMyContext();

  const [quanity, increaseTicketQuantity, decreaseTicketQuantity] =
    useQuantity();

  console.log('quanity', quanity);
  // const increaseTicketQuantity = () => {
  //   console.log(pkg?.quantity * 1, pkg);
  //   // package quanity is 0 means we can add unlimited product
  //   if (pkg?.quantity === 0) {
  //     setTicketQuantity(ticketQuantity + 1);
  //   } else {
  //     if (ticketQuantity >= pkg?.quantity * 1) return;
  //     setTicketQuantity(ticketQuantity + 1);
  //   }
  // };
  // const decreaseTicketQuantity = () => {
  //   if (ticketQuantity <= 1) return;

  //   setTicketQuantity(ticketQuantity - 1);
  // };

  const checkForLogin = (el) => {
    setTicketQuantity(quanity);

    if (!user.loggedIn && !userEmail && !cookies?.ue) {
      // we will need UsemEmail to send the bill so we will open modal
    } else if (!user.loggedIn && (userEmail || cookies?.ue)) {
      // user wnats to buy from email
      return buyButtonAction(el);
    } else if (user.loggedIn) {
      // buy as a loggedIn user
      return buyButtonAction(el);
    }
  };
  useEffect(() => {
    if (email) {
      setUserEmail(email);
      setCookie('ue', email, {
        path: '/',
      });
    }
  }, [email, setCookie, setUserEmail]);
  console.log(pkg?.category?.contentType);

  return (
    <>
      <div
        className={`${classes.packageComponent} `}
        style={{ background: pkg.price === 10 && '#009665' }}
        key={pkg.id}
      >
        <div className={classes.packageTitle}>{pkg.name}</div>
        <div className={classes.packagePrice}>
          {pkg.price} {convertCurrency(pkg.currency)}
        </div>
        <div
          className={classes.packageDescription}
          dangerouslySetInnerHTML={{ __html: pkg.description }}
        />
        <div className={classes.packageBuyButtonContainer}>
          {userPackages?.findIndex(
            (userPkg) =>
              userPkg.sku === pkg.sku &&
              userPkg.validFrom < Date.now() &&
              userPkg.validTo > Date.now()
          ) !== -1 ? (
            <div id={pkg.id} name={pkg.id}>
              <Button className={classes.packageBoughtButtonText} disabled>
                {t('packages.Bought')}
              </Button>
            </div>
          ) : (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
              {pkg?.category?.contentType === 3 && pkg?.quantity > 1 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    gap: '10px',
                    marginTop: '5px',
                  }}
                >
                  <div style={{ fontWeight: 600 }}>Quantity</div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <Button
                      onClick={decreaseTicketQuantity}
                      disabled={quanity === 1}
                      style={{
                        padding: '10px 20px ',
                        height: '38px',
                      }}
                    >
                      -
                    </Button>
                    <input
                      value={quanity}
                      style={{
                        width: '50px',
                        flex: 1,
                        textAlign: 'center',
                        height: '38px',
                        outline: 'none',
                        border: 'none',
                      }}
                      onChange={(e) => {
                        // if (pkg?.id === 179112980 || pkg?.id === 179112979) {
                        //   if (!isNaN(e.target.value)) {
                        //     setTicketQuantity(e.target.value);
                        //   }
                        // }
                      }}
                      // onChange={(e) => AllowOnlyNumbers(e)}
                      type='number'
                    />

                    <Button
                      onClick={() => increaseTicketQuantity(pkg)}
                      disabled={
                        pkg?.quantity !== 0 && quanity === pkg?.quantity
                      }
                      style={{
                        padding: '10px  20px',
                        height: '38px',
                        border: '1px solid black',
                      }}
                      inverse
                    >
                      +
                    </Button>
                  </div>
                </div>
              )}
              <div id={pkg.id} name={pkg.id} onClick={() => checkForLogin(pkg)}>
                {pkg?.category?.contentType === 3 ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}
                  >
                    <Button
                      className={classes.packageBuyButtonText}
                      disabled={!isValid}
                    >
                      {/* {t('packages.Order')} */}
                      {pkg.id === 179112980 || pkg.id === 179112979
                        ? 'Donate'
                        : 'Buy Tickets'}
                    </Button>
                  </div>
                ) : (
                  <Button
                    className={classes.packageBuyButtonText}
                    disabled={!isValid}
                  >
                    {t('packages.Order')}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PackageComponent;
