import { useMyContext } from '../../contexts/StateHolder';
import DetailsFolderNames from './DetailsFolderNames';
import DetailsContentRatings from './DetailsContentRatings';
import DetailsInfoData from './DetailsInfoData';
import DetailsItemDescription from './DetailsItemDescription';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  createAssetIdToken,
  createToken,
  removePunctuation,
} from '../../scripts/utils';
import {
  findSubOrganizationSecret,
  getAsset,
  getSubCategories,
} from '../../scripts/dataHandlers';
import DetailsAssetBanner from './DetailsAssetBanner';
import DetailsShare from './DetailsShare';
import * as classes from './DetailsVideo.module.css';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useCookies } from 'react-cookie';
import AssetVideoPlayer from '../VideoPlayer/AssetVideoPlayer';

// import HelmetMetaData from '../ShareSocialMedia/HelmetMetaData';
const DetailsVideo = (props) => {
  // Bring stateholders from context
  const {
    chosenItem,
    setChosenItem,
    language,
    key,
    organizationId,
    user,
    loading,
    setLoading,
  } = useMyContext();

  const { asset, orgId } = useParams();

  const [vodAccessRequired, setVodAccessRequired] = useState(true);

  const history = useHistory();
  const location = useLocation();

  const [cookies, setCookie, removeCookie] = useCookies('');

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const checkUserAccess = (assetResponse) => {
      if (
        assetResponse?.accessInfo?.products?.length === 0 ||
        assetResponse?.accessInfo.access === 1
      ) {
        setVodAccessRequired(false);
      } else {
        setVodAccessRequired(true);
      }
      setLoading(false);
    };

    async function fetchAsset() {
      setChosenItem(null);
      setLoading(true);
      try {
        // here we also have to check if organizationId from config matches the organizationID we get. if it does not it should be suborg and we need different key
        let token, assetResponse;

        if (organizationId * 1 !== orgId * 1) {
          let subKey;

          const getAllSubOrganization = async () => {
            subKey = await findSubOrganizationSecret(
              organizationId,
              key,
              orgId
            );

            token = createAssetIdToken(orgId, asset, language, subKey?.secret);
            assetResponse = await getAsset(
              orgId,
              asset,
              token,
              language,
              undefined, // TODO: MOVE TO COMPONENT CONFIG
              user,
              source
            );
            setChosenItem(assetResponse);
            checkUserAccess(assetResponse);

            console.log(`assetResponse`, assetResponse);
          };
          getAllSubOrganization();
        } else {
          token = createAssetIdToken(organizationId, asset, language, key);
          assetResponse = await getAsset(
            orgId,
            asset,
            token,
            language,
            undefined, // TODO: MOVE TO COMPONENT CONFIG
            user,
            source
          );
          setChosenItem(assetResponse);
          console.log('assetResponse', assetResponse);
          checkUserAccess(assetResponse);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }

    const fetchSerieCategoryAsset = async () => {
      setChosenItem(null);

      console.log(2);
      setLoading(true);

      try {
        let token3, response3;
        if (organizationId * 1 !== orgId * 1) {
          const getAllSubOrganization = async () => {
            let subKey;

            subKey = await findSubOrganizationSecret(
              organizationId,
              key,
              orgId
            );

            token3 = createToken(orgId, asset, subKey?.secret);
            response3 = await getAsset(
              orgId,
              asset,
              token3,
              language,
              undefined, // TODO: MOVE TO COMPONENT CONFIG
              user,
              source
            );
            setChosenItem(response3[0]);
            checkUserAccess(response3[0]);
          };
          getAllSubOrganization();
        } else {
          token3 = createToken(organizationId, asset, key);

          response3 = await getSubCategories(
            organizationId,
            token3,
            asset,
            language,
            user,
            source
          );
          console.log(`response3`, response3);

          setChosenItem(response3[0]);
          checkUserAccess(response3[0]);
        }
      } catch (error) {
        setLoading(false);
      }
    };

    if (language) {
      props.isSerieCategory ? fetchSerieCategoryAsset() : fetchAsset();
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    asset,
    key,
    language,
    organizationId,
    props.isSerieCategory,
    user,
    orgId,
  ]);

  console.log('chosenItem', chosenItem);

  const redirectUserBuyPackage = () => {
    //  If there's product available, but user doesnt have access, push to packages route
    setCookie(
      'packageReturn',
      { returnPath: location.pathname },
      { path: '/' }
    );
    removeCookie('packageType', {
      path: '/',
    });
    setTimeout(() => {
      setCookie('packageType', 'vodasset', {
        path: '/',
      });
      history.push(`/${props.routes.packages}/asset/${asset}`);
    }, 100);
  };

  const renderDetails = () => {
    return (
      <>
        {!vodAccessRequired ? (
          <AssetVideoPlayer backRoute={'/'} playerAutoStart={true} />
        ) : (
          <div className='detailsContainer'>
            <DetailsAssetBanner
              routes={props.routes}
              hideCta={props.hideCta}
              onClick={redirectUserBuyPackage}
            />
          </div>
        )}

        <div className='detailsContainer'>
          {!props.hideBanner && chosenItem?.bannerImageSmall ? (
            <DetailsAssetBanner routes={props.routes} hideCta={props.hideCta} />
          ) : null}

          <div className={classes.details_description_container}>
            <div className={classes.detailsDescriptionTitleContainer}>
              <div className={classes.details_description_title_left}>
                <div
                  className={`${classes.details_description_title_name} font-600`}
                >
                  {`${removePunctuation(
                    chosenItem?.name ? chosenItem.name : chosenItem.title
                  )}`}
                </div>
              </div>

              {chosenItem.contentRatings?.length > 0 && (
                <div className={classes.details_description_title_right}>
                  <DetailsContentRatings />{' '}
                </div>
              )}
            </div>
            <div className='details-description-info-container'>
              <div className='detailsDescriptionContainer'>
                <DetailsItemDescription
                  showAll={true}
                  desc={
                    chosenItem.ingress ||
                    chosenItem?.description ||
                    chosenItem?.serie?.description
                  }
                  size='1000000'
                  mobileSize='1000000'
                  sanitizeHTML={true}
                />
              </div>

              {!props.hideInfoData ? (
                <div className='detailsInfoContainer'>
                  <DetailsInfoData item={chosenItem} />
                </div>
              ) : null}
            </div>
            {props.showPublishedDate && (
              <div
                className='asset-date font-000'
                style={{ paddingTop: '0px' }}
              >
                {moment(chosenItem.date).locale('fr').format('l')}
              </div>
            )}

            {props.hideShare ? (
              <div
                className='asset-date font-000'
                style={{ paddingTop: '0px' }}
              >
                {moment(chosenItem.date).locale('fr').format('l')}
              </div>
            ) : (
              <DetailsShare
                chosenItem={chosenItem}
                isSerieCategory={props.isSerieCategory}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  // TODO: If /detailsVideo, take parameters and render loading while fetching data or something like that
  return chosenItem
    ? renderDetails()
    : !chosenItem && loading && (
        <div className='display-flex-center'>
          <Loader type='TailSpin' color='#161eaf' />
        </div>
      );
};

export default DetailsVideo;
