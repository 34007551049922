import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { checkTicketNumber } from '../../../scripts/dataHandlers';
import AssetVideoPlayer from '../../VideoPlayer/AssetVideoPlayer';
import EventsRoomDescription from './EventsRoomDescription';

import * as classes from '../Event/UpcomingEvent.module.css';
import { toast } from 'react-toastify';

const CompletedEventWithRecording = ({
  routes,
  singleEvent,
  setTicketAccessGranted,
  ticketAccessGranted,
  loading,
  ticketAccess,
  recordingAssetId,
  isLiveEvents,
  defaultLanguage,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['tiAcc']);

  console.warn('recording');
  const [completedRecordingAssetId, setCompletedRecordingAssetId] =
    useState(null);

  const checkForVideoAccess = useCallback(async () => {
    const res = await checkTicketNumber(singleEvent?.eventId, cookies?.tiAcc);
    if (res.data.status === 'ok') {
      let purchaseTime = res?.data?.ts;

      function addDays(date, days) {
        var result = new Date(Number(date));
        result.setDate(result.getDate() + days);
        return result.getTime();
      }

      let daysToBeAdded = res?.data?.period / 24;

      let expiryTime = addDays(purchaseTime, daysToBeAdded);
      let currentTime = Date.now();

      // setCookie('tiAcc', cookies?.tiAcc, { path: '/' });
      // setCompletedRecordingAssetId(singleEvent.rooms[0].recordings[0]?.assetId);

      if (currentTime > expiryTime) {
        removeCookie('tiAcc', { path: '/' });
        return toast.error('Ticket Has Expired.');
      } else {
        let remaining_time =
          (new Date(expiryTime).getTime() - new Date(currentTime).getTime()) /
          1000;

        console.log('remaining_time1', remaining_time);

        setCookie('tiAcc', cookies?.tiAcc, {
          path: '/',
          expires: new Date(Date.now() + 1000 * Math.ceil(remaining_time)),
        });
        setCompletedRecordingAssetId(
          singleEvent.rooms[0].recordings[0]?.assetId
        );
      }
    } else {
      // setTicketAccessGranted(false);
      removeCookie('tiAcc', { path: '/' });
    }
  }, []);
  useEffect(() => {
    if (ticketAccessGranted && singleEvent?.eventId && cookies?.tiAcc) {
      checkForVideoAccess();
    }
  }, [ticketAccessGranted, singleEvent?.eventId, cookies?.tiAcc]);
  console.log(
    'recording',
    ticketAccess,
    ticketAccessGranted,
    completedRecordingAssetId
  );

  return (
    <>
      {/* // this will work when there is access control applied to recording , wee have prevented a loophole by passing completedRecordingAssetId as a parameter */}
      {ticketAccessGranted &&
        ticketAccess &&
        completedRecordingAssetId &&
        cookies?.tiAcc &&
        !loading && (
          <div className={classes.SecondaryContainer}>
            <AssetVideoPlayer
              asset={completedRecordingAssetId}
              isEvent={true}
            />
          </div>
        )}
      {/* // will work when no access is applied */}
      {ticketAccessGranted && !ticketAccess && !loading && (
        <div className={classes.SecondaryContainer}>
          <AssetVideoPlayer asset={recordingAssetId} isEvent={true} />
        </div>
      )}

      {!loading && (
        <EventsRoomDescription
          liveEvent={isLiveEvents}
          routes={routes}
          ticketAccessGranted={ticketAccessGranted}
          singleEvent={singleEvent}
          ticketAccess={ticketAccess}
          defaultLanguage={defaultLanguage}
          setTicketAccessGranted={setTicketAccessGranted}
          futureEvents={false}
        />
      )}
    </>
  );
};

export default CompletedEventWithRecording;
